const mockData = [
  {
    id: 1,
    url: "www.github.com",
    description:
      "GitHub is where over 100 million developers shape the future of software, together. Contribute to the open source community, manage your Git repositories, ...",
  },
  {
    id: 2,
    url: "www.amazon.com",
    description:
      "Shop Top Brands & Products — Browse & discover millions of products. Read customer reviews and find best sellers. Yes, we ship to you. Shop top brands in electronics, clothing, books & more. for free with online versions of Microsoft Word, PowerPoint, Excel, and OneNote. Save documents, workbooks, and presentations online",
  },
  {
    id: 3,
    url: "www.aau.edu.jo",
    description:
      "The first bachelor programs in Jordanian universities in cooperation with MidEast Aviation Academy: Aircraft Maintenance specialization; Avionics.",
  },
  {
    id: 4,
    url: "www.udemy.com",
    description:
      "Find the right instructor for you. Choose from many topics, skill levels, and languages. Shop thousands of high-quality on-demand online courses.",
  },
  {
    id: 5,
    url: "www.edx.org",
    description:
      "Master In-Demand Skills from the World's Top Universities on edX and Propel Your Career. Join 44 Million+ Learners on the World's...",
  },
];

export default mockData;
